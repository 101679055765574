import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class notificationsService {
  async getNotifications(companyId) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/notification/get-by-company/${companyId}`,
    };
    return axios.get(option.url, config);
  }

  async getNotification(id) {
    const token = loginservice.getToken();
    return axios.get(`${API_URL}/notification/get/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async addNotifications(data) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/notification`,
    };
    return axios.post(option.url, data, config);
  }

  async updateNotification(data, queryParams) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/notification${queryParams}`,
    };
    return axios.put(option.url, data, config);
  }

  async deleteNotification(queryParams) {
    const token = loginservice.getToken();
    return axios.delete(`${API_URL}/notification${queryParams}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async getNotificationSettingsInfo() {
    const token = loginservice.getToken();
    return axios.get(`${API_URL}/notification/settings_info`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async getValidApps() {
    const token = loginservice.getToken();
    return axios.get(`${API_URL}/notification/valid_apps`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async getSimulationNotifications() {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/storage_insights/getNotifications`,
    };
    return axios.get(option.url);
  }

  async updateSimulationNotifications(data) {
    const obj = {
      id: data,
    };
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/storage_insights/alert_status_update`,
    };
    return axios.put(option.url, obj, config);
  }
}
